import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "./useAuth";

export function RequireAuth({ children }) {
  const location = useLocation();
  const { jwt } = useAuth();

  return jwt !== "" ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}
