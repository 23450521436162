import React, { useEffect } from "react";
import Page from "../components/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";
import Cookies from "js-cookie";

const Home = () => {
  const [Username, SetUsername] = React.useState("");
  const [Password, SetPassword] = React.useState("");
  const navigate = useNavigate();
  const { login, jwt, authenticate } = useAuth();
  const { state } = useLocation();

  useEffect(() => {
    if (jwt !== "") {
      navigate("/storeoverview");
    }
  }, []);

  const InputChanged = (FieldType, evt) => {
    if (FieldType === "Username") {
      SetUsername(evt.target.value);
    } else if (FieldType === "Password") {
      SetPassword(evt.target.value);
    }
  };

  const handleLogin = () => {
    login({ Username, Password }).then(() => {
      const vToken = Cookies.get("token");
      authenticate(vToken, Username);
      navigate(state?.path || "/storeoverview");
    });
  };

  const handleKeypress = e => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };
  

  return (
    <Page>
      <div className="row pt-3">
        <div className="col-4"></div>
        <div className="col-4">
          <h5>Login</h5>
          <div className="row pt-3">
            <div className="col-12">
              <input
                type="text"
                id="Username"
                name="Username"
                placeholder="Username"
                className="form-control"
                onChange={(evt) => InputChanged("Username", evt)}
                onKeyPress={handleKeypress}
                autoComplete="username"
              />
            </div>
            <div className="col-12 pt-2">
              <input
                type="password"
                id="Password"
                name="Password"
                placeholder="Password"
                className="form-control"
                onChange={(evt) => InputChanged("Password", evt)}
                onKeyPress={handleKeypress}
                autoComplete="current-password"
              />
            </div>
            <div className="col-12 pt-2">
              <a className="btn btn-checkout float-end" onClick={handleLogin}>
                Login
              </a>
            </div>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
    </Page>
  );
};

export default Home;
