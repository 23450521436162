import React, { useState } from "react";
import Page from "../components/Page";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";

const NewProduct = () => {
  const [searchParams] = useSearchParams();
  const StoreID = searchParams.get("store-id");
  const [ProductName, SetProductName] = useState("");
  const [ProductURL, SetProductURL] = useState("");
  const [ProductDesciption, SetProductDesciption] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();

  const InputChanged = (FieldType, evt) => {
    if (FieldType === "ProductName") {
      SetProductName(evt.target.value);
    } else if (FieldType === "ProductURL") {
      SetProductURL(evt.target.value);
    } else if (FieldType === "ProductDesciption") {
      SetProductDesciption(evt.target.value);
    }
  };

  const SaveProduct = () => {
    fetch("/api/storemanager/newproduct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        StoreID,
        ProductName,
        ProductURL,
        ProductDesciption,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        //Get Product ID the navigate to edit it
        navigate(
          "/editproduct?store-id=" +
            StoreID +
            "&product-id=" +
            jsonRes.recordset[0].Product_ID
        );
      });
  };

  return (
    <Page>
      <h2>New Product</h2>
      <div className="row">
        <div className="col-2">
          <a href={"/manageproducts?store-id=" + StoreID}>Back to Products</a>
        </div>
        <div className="col-8">
          <div className="col-12">
            <input
              className="form-control"
              id="ProductName"
              placeholder="Product Name"
              onChange={(evt) => InputChanged("ProductName", evt)}
            />
          </div>
          <div className="col-12 mt-2">
            <input
              className="form-control"
              id="ProductURL"
              placeholder="Product URL"
              onChange={(evt) => InputChanged("ProductURL", evt)}
            />
          </div>
          <div className="col-12 mt-2">
            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder="Write HTML..."
                id="ProductDesciption"
                style={{ height: "500px", overflow: "scroll" }}
                onChange={(evt) => InputChanged("ProductDesciption", evt)}
              ></textarea>
              <label htmlFor="ProductDesciption">
                Product Description (HTML)
              </label>
            </div>
          </div>
          <div className="col-12 mt-2">
            <a className="btn btn-save-product float-end" onClick={SaveProduct}>
              Save Product
            </a>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </Page>
  );
};

export default NewProduct;
