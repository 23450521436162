import React from "react";
import Page from "../components/Page";
import { useSearchParams } from "react-router-dom";

const ManageStore = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const StoreID = searchParams.get("store-id");

  return (
    <Page>
      <div className="pt-3">
        <h5>Manage Store</h5>
        <div className="row pt-4">
          <div className="col-3">
            <a href="/storeoverview">Back to Store Overview</a>
          </div>
          <div className="col-6">
            <a href={"manageproducts?store-id=" + StoreID}>Manage Products</a>
          </div>
          <div className="col-3"></div>
        </div>
      </div>
    </Page>
  );
};

export default ManageStore;
