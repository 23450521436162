import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";

const ManageProductVariantImages = (params) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [StoreID, SetStoreID] = useState("");
  const [ProductVariant_ID, SetProductVariant_ID] = useState("");
  const [UniqueFormID, SetUniqueFormID] = useState(0);
  const [ProductImages, SetProductImages] = useState(null);
  const [LoadingInProgress, SetLoadingInProgress] = useState(false);

  const ShowToast = (pHeader, pMsg) => {
    if (typeof params.ShowToast === "function") {
      params.ShowToast(pHeader, pMsg);
    }
  };

  const GetProductImages = useCallback(() => {
    fetch(
      "/api/storemanager/getproductvariantimages/" +
        StoreID +
        "/" +
        ProductVariant_ID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        SetProductImages(jsonRes.recordset);
      });
  }, [ProductVariant_ID, StoreID, logout, navigate]);

  const ImageHandler = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    fetch(
      "/api/storemanager/saveproductvariantimage/" +
        StoreID +
        "/" +
        ProductVariant_ID,
      {
        method: "POST",
        body: formData,
        headers: { Accept: "multipart/form-data" },
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          ShowToast("Success", "Image Uploaded");
          GetProductImages();
          return;
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (StoreID === "") {
      SetStoreID(params.StoreID);
    }
    if (ProductVariant_ID === "") {
      SetProductVariant_ID(params.ProductVariant_ID);
    }

    if (UniqueFormID === 0) {
      SetUniqueFormID(params.ProductVariant_ID);
    }

    if (!ProductImages && !LoadingInProgress && ProductVariant_ID && StoreID) {
      SetLoadingInProgress(true);
      GetProductImages();
    }
  }, [
    StoreID,
    UniqueFormID,
    params.ProductVariant_ID,
    params.StoreID,
    ProductImages,
    ProductVariant_ID,
    LoadingInProgress,
    GetProductImages,
  ]);

  const ImageUploadMarkup = (
    <div className="col-4">
      <button className="btn">
        <label htmlFor={UniqueFormID}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-upload"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
          </svg>
        </label>
        <input
          type="file"
          name="image"
          accept="image/*"
          id={UniqueFormID}
          className="form-control d-none"
          multiple={false}
          onChange={ImageHandler}
        />
      </button>
    </div>
  );

  if (ProductImages) {
    return (
      <React.Fragment>
        {ProductImages.map((rec, index) => (
          <div className="col-4" key={rec.ID}>
            <img
              src={
                rec.ImageURL
              }
              alt="Failed to load"
              className="img-fluid"
            />
          </div>
        ))}
        {ImageUploadMarkup}
      </React.Fragment>
    );
  } else {
    return ImageUploadMarkup;
  }
};

export default ManageProductVariantImages;
