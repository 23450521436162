import React, { Component } from "react";
import "./Footer.css";

//tetst

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <div className="container"></div>
      </div>
    );
  }
}

export default Footer;
