import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./custom.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { BrowserRouter } from "react-router-dom";

document.addEventListener("DOMContentLoaded", async () => {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
});
