import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";
import ManageProductVariantImages from "../components/ManageProductVariantImages";

const ProductVariants = (params) => {
  const StoreID = params.StoreID;
  const ProductID = params.ProductID;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [ProductVariants, SetProductVariants] = useState(null);
  const [LoadingInProgress, SetLoadingInProgress] = useState(false);

  const [ProductVariantTitle, SetProductVariantTitle] = useState("");
  const [ProductVariantDescription, SetProductVariantDescription] =
    useState("");
  const [ProductPrice, SetProductPrice] = useState("");
  const [ProductComparePrice, SetProductComparePrice] = useState("");

  const InputChanged = (FieldType, evt) => {
    if (FieldType === "ProductVariantTitle") {
      SetProductVariantTitle(evt.target.value);
    } else if (FieldType === "ProductVariantDescription") {
      SetProductVariantDescription(evt.target.value);
    } else if (FieldType === "ProductPrice") {
      SetProductPrice(evt.target.value);
    } else if (FieldType === "ProductComparePrice") {
      SetProductComparePrice(evt.target.value);
    }
  };

  const GetProductVariants = useCallback(() => {
    fetch("/api/storemanager/getproductvariants/" + StoreID + "/" + ProductID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        SetProductVariants(jsonRes.recordset);
      });
  }, [ProductID,StoreID,logout,navigate]);

  const AddNewProductVariant = () => {
    fetch("/api/storemanager/newproductvariant", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        StoreID,
        ProductID,
        ProductVariantTitle,
        ProductVariantDescription,
        ProductPrice,
        ProductComparePrice,
      }),
    }).then((response) => {
      if (response.status === 200) {
        ShowToast("Success", "Variant Added");
        GetProductVariants();
      } else if (response.status === 401) {
        logout();
        navigate("/");
      }
    });
  };

  const ShowToast = (pHeader, pMsg) => {
    if (typeof params.ShowToast === "function") {
      params.ShowToast(pHeader, pMsg);
    }
  };

  const SaveVariantChange = (pRec) => {
    console.log(pRec.ID);
    const vVariant_ID = pRec.ID;
    const vVariantTitle = pRec.ProductVariantTitle;
    const vVariantDesc = pRec.ProductVariantDescription;
    const vVariantPrice = pRec.ProductPrice;
    const vVariantComparePrice = pRec.ProductComparePrice;

    fetch("/api/storemanager/saveproductvariantchange", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        StoreID,
        vVariant_ID,
        vVariantTitle,
        vVariantDesc,
        vVariantPrice,
        vVariantComparePrice,
      }),
    }).then((response) => {
      if (response.status === 200) {
        ShowToast("Success", "Variant Change Saved");
        GetProductVariants();
      } else if (response.status === 401) {
        logout();
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (!ProductVariants && !LoadingInProgress) {
      SetLoadingInProgress(true);
      GetProductVariants();
    }
  }, [ProductVariants, LoadingInProgress, GetProductVariants]);

  const NewProdVariantMarkup = (
    <div className="row mt-2">
      <div className="col-3">
        <input
          className="form-control"
          name="ProductVariantTitle"
          placeholder="Color"
          onChange={(evt) => InputChanged("ProductVariantTitle", evt)}
        ></input>
      </div>
      <div className="col-3">
        <input
          className="form-control"
          name="ProductVariantDescription"
          placeholder="Green"
          onChange={(evt) => InputChanged("ProductVariantDescription", evt)}
        ></input>
      </div>
      <div className="col-2">
        <input
          className="form-control"
          name="ProductPrice"
          placeholder="Price"
          onChange={(evt) => InputChanged("ProductPrice", evt)}
        ></input>
      </div>
      <div className="col-2">
        <input
          className="form-control"
          name="ProductComparePrice"
          placeholder="Compare Price"
          onChange={(evt) => InputChanged("ProductComparePrice", evt)}
        ></input>
      </div>
      <div className="col-2">
        <button
          className="btn btn-save-product float-end"
          onClick={AddNewProductVariant}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-plus-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        </button>
      </div>
    </div>
  );

  if (ProductVariants) {
    return (
      <div>
        {ProductVariants.map((rec, index) => (
          <div className="row mt-2" key={rec.ID}>
            <div className="col-3">
              <input
                className="form-control"
                name="ProductVariantTitle"
                defaultValue={rec.ProductVariantTitle}
                onChange={(e) => (rec.ProductVariantTitle = e.target.value)}
              ></input>
            </div>
            <div className="col-3">
              <input
                className="form-control"
                name="ProductVariantDescription"
                defaultValue={rec.ProductVariantDescription}
                onChange={(e) =>
                  (rec.ProductVariantDescription = e.target.value)
                }
              ></input>
            </div>
            <div className="col-2">
              <input
                className="form-control"
                name="ProductPrice"
                defaultValue={rec.ProductPrice}
                onChange={(e) => (rec.ProductPrice = e.target.value)}
              ></input>
            </div>
            <div className="col-2">
              <input
                className="form-control"
                name="ProductComparePrice"
                defaultValue={rec.ProductComparePrice}
                onChange={(e) => (rec.ProductComparePrice = e.target.value)}
              ></input>
            </div>
            <div className="col-2">
              <div className="row">
                <ManageProductVariantImages
                  key={index}
                  StoreID={StoreID}
                  ProductVariant_ID={rec.ID}
                />
                <div className="col-4">
                  <button
                    className="btn btn-save-product float-end"
                    onClick={() => SaveVariantChange(rec)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-save"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {NewProdVariantMarkup}
      </div>
    );
  } else {
    return NewProdVariantMarkup;
  }
};

export default ProductVariants;
