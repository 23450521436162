import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";

const ManageProductImages = (params) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const StoreID = params.StoreID;
  const ProductID = params.ProductID;
  const [ProductImages, SetProductImages] = useState(null);
  const [LoadingInProgress, SetLoadingInProgress] = useState(false);

  const ShowToast = (pHeader, pMsg) => {
    if (typeof params.ShowToast === "function") {
      params.ShowToast(pHeader, pMsg);
    }
  };

  const GetProductImages = useCallback(() => {
    fetch("/api/storemanager/getproductimages/" + StoreID + "/" + ProductID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        SetProductImages(jsonRes.recordset);
      });
  }, [StoreID, ProductID, logout, navigate]);

  const ImageHandler = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    fetch("/api/storemanager/saveproductimage/" + StoreID + "/" + ProductID, {
      method: "POST",
      body: formData,
      headers: { Accept: "multipart/form-data" },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          ShowToast("Success", "Image Uploaded");
          GetProductImages();
          return;
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!ProductImages && !LoadingInProgress) {
      SetLoadingInProgress(true);
      GetProductImages();
    }
  }, [ProductImages, LoadingInProgress, GetProductImages]);

  const ImageUploadMarkup = (
    <div className="row">
      <div className="col-12 mt-3">
        <input
          type="file"
          name="image"
          accept="image/*"
          id="ProductImageUpload"
          className="form-control"
          multiple={false}
          onChange={ImageHandler}
        />
      </div>
    </div>
  );

  if (ProductImages) {
    return (
      <div>
        <div className="row">
          {ProductImages.map((rec, index) => (
            <div className="col-4" key={index}>
              <img
                src={
                  rec.ImageURL
                }
                alt="Failed to load"
                className="img-fluid"
              />
            </div>
          ))}
        </div>
        {ImageUploadMarkup}
      </div>
    );
  } else {
    return ImageUploadMarkup;
  }
};

export default ManageProductImages;
