import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const getJwt = () => {
  return localStorage.getItem("jwt") || "";
};

const setJwt = (jwt) => {
  localStorage.setItem("jwt", jwt);
};

const getUser = () => JSON.parse(localStorage.getItem("user") || "null");

const setUser = (user) => localStorage.setItem("user", JSON.stringify(user));

const logout = () => localStorage.clear();

const AuthContext = createContext({
  jwt: getJwt,
  setJwt: setJwt,
  user: {},
  setUser: setUser,
  loading: false,
  setLoading: (loading) => {},
  authenticate: (jwt, user) => {},
  logout: logout,
  login: (pCredentials) => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const Auth = ({ children }) => {
  const auth = useAuth();

  const [jwt, updateJwt] = useState(auth.jwt);
  const [user, updateUser] = useState(auth.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateJwt(getJwt());
    updateUser(getUser());
  }, []);

  const value = {
    jwt: jwt,
    setJwt: (jwt) => {
      setJwt(jwt);
      updateJwt(jwt);
    },
    user: user,
    setUser: (user) => {
      setUser(user);
      updateUser(user);
    },
    loading: loading,
    setLoading: setLoading,
    authenticate: (jwt, user) => {
      setJwt(jwt);
      updateJwt(jwt);
      setUser(user);
      updateUser(user);
    },
    logout: () => {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      Cookies.remove("token");
      updateJwt("");
      updateUser({});
      setLoading(false);
    },
    login: (pCredentials) => {
      return fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(pCredentials),
      }).then((data) => {
        return data;
      });
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default Auth;
