import React, { Component } from "react";
import Page from "../components/Page";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";

const StoreOverview = () => {
  const [storeData, setStoreData] = React.useState(null);
  const [loadingInProgress, setLoadingInProgress] = React.useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  React.useEffect(() => {
    if (storeData === null && !loadingInProgress) {
      setLoadingInProgress(true);
      fetch("/api/getstores", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            logout();
            navigate("/");
          }
        })
        .then((jsonRes) => {
          setStoreData(jsonRes.recordset);
          setLoadingInProgress(false);
        });
    }
  }, [storeData, logout, navigate]);

  if (!storeData) {
    return (
      <Page>
        <div className="pt-3">
          <h5>Store Overview</h5>
        </div>
      </Page>
    );
  } else {
    return (
      <Page>
        <div className="pt-3">
          <h5>Store Overview</h5>
          {storeData.map((rec, index) => (
            <div className="storeContainer row" key={rec.ID}>
              <div className="col-4">
                <div>{rec.StoreName}</div>
                <div>{rec.StoreDBName}</div>
                <div>
                  <a href={rec.StoreURL}>{rec.StoreURL}</a>
                </div>
                <div>
                  <a href={"/managestore?store-id=" + rec.ID}>Manage Store</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Page>
    );
  }
};

export default StoreOverview;
