import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";

const ManageProducts = () => {
  const [searchParams] = useSearchParams();
  const StoreID = searchParams.get("store-id");
  const [loadingInProgress, setLoadingInProgress] = React.useState(false);
  const [storeProducts, setStoreProducts] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingInProgress && storeProducts === null) {
      GetProducts();
    }
  }, [storeProducts, loadingInProgress]);

  const GetProducts = () => {
    setLoadingInProgress(true);
    fetch("/api/storemanager/getproducts/" + StoreID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        console.log(jsonRes.recordset);
        setStoreProducts(jsonRes.recordset);
        setLoadingInProgress(false);
      });
  };

  if (storeProducts !== null) {
    return (
      <Page>
        <div className="pt-3">
          <h5>Manage Products</h5>
          <div className="row mt-3">
            <div className="col-3">
              <a href={"/managestore?store-id=" + StoreID}>
                Back to Manage Store
              </a>
            </div>
            <div className="col-6">
              <a href={"/newproduct?store-id=" + StoreID}>New Product</a>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="row mt-3">
            {storeProducts.map((rec, index) => (
               <div className="col-4 storeContainer mb-5" key={rec.ID}>
               <img
                 className="card-img-top"
                 style={{ maxWidth: "200px" }}
                 alt="Main Image not configured"
                 src={
                  rec.ImageURL
                 }
               />
                <div>{rec.ProductName}</div>
                <div>
                  <a
                    href={
                      "/editproduct?store-id=" +
                      StoreID +
                      "&product-id=" +
                      rec.ID
                    }
                  >
                    Edit Product
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Page>
    );
  } else {
    return (
      <Page>
        <div className="pt-3">
          <h1>Store Manager</h1>
          <h5>Manage Products</h5>
          <span>Loading products...</span>
        </div>
      </Page>
    );
  }
};

export default ManageProducts;
