import React, { useState, useEffect, useRef, useCallback } from "react";
import Page from "../components/Page";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ManageProductImages from "../components/ManageProductImages";
import ProductVariants from "../components/ProductVariants";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const EditProduct = () => {
  const [searchParams] = useSearchParams();
  const StoreID = searchParams.get("store-id");
  const ProductID = searchParams.get("product-id");
  const [show, setShow] = useState(false);

  const [ToastHeader, SetToastHeader] = useState("");
  const [ToastMessage, SetToastMessage] = useState("");

  const [ProductName, SetProductName] = useState("");
  const [ProductURL, SetProductURL] = useState("");
  const [ProductDesciption, SetProductDesciption] = useState("");
  const [ProductDesciptionInitialValue, SetProductDesciptionInitialValue] =
    useState("");

  const [LoadingInProgress, SetLoadingInProgress] = useState(false);
  const [ProductDetails, SetProductDetails] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const LoadProductDetails = useCallback(() => {
    fetch("/api/storemanager/getproductdetails/" + StoreID + "/" + ProductID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          logout();
          navigate("/");
        }
      })
      .then((jsonRes) => {
        if (jsonRes.recordsets.length > 0) {
          SetProductDetails(jsonRes.recordset[0]);
          SetProductName(jsonRes.recordset[0].ProductName);
          SetProductURL(jsonRes.recordset[0].ProductCustomURL);
          SetProductDesciption(jsonRes.recordset[0].ProductDescription);
          SetProductDesciptionInitialValue(
            jsonRes.recordset[0].ProductDescription
          );
        }
        SetLoadingInProgress(false);
      });
  },[ProductID,StoreID,logout,navigate]);

  const SaveProductChanges = () => {
    fetch("/api/storemanager/saveproductchange", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        StoreID,
        ProductID,
        ProductName,
        ProductURL,
        ProductDesciption,
      }),
    }).then((response) => {
      if (response.status === 200) {
        ShowToast("Success", "Changes Saved");
      } else if (response.status === 401) {
        logout();
        navigate("/");
      }
    });
  };

  const ShowToast = (pHeader, pMsg) => {
    SetToastHeader(pHeader);
    SetToastMessage(pMsg);
    setShow(true);
  };

  useEffect(() => {
    if (!ProductDetails && !LoadingInProgress) {
      SetLoadingInProgress(true);
      LoadProductDetails();
    }
  }, [ProductDetails, LoadingInProgress, LoadProductDetails]);

  const InputChanged = (FieldType, evt) => {
    if (FieldType === "ProductName") {
      SetProductName(evt.target.value);
    } else if (FieldType === "ProductURL") {
      SetProductURL(evt.target.value);
    } else if (FieldType === "ProductDesciption") {
      SetProductDesciption(evt);
    }
  };

  return (
    <Page>
      <ToastContainer position="top-start" style={{ zIndex: 9999 }}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">{ToastHeader}</strong>
          </Toast.Header>
          <Toast.Body>{ToastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <h2 className="py-3">Edit Product</h2>
      <div className="row">
        <div className="col-2">
          <div className="col-12">
            <a href={"/manageproducts?store-id=" + StoreID}>Back to Products</a>
          </div>
          <div className="col-12">
            <a
              href={
                "https://dreamdesignstore.com/Products/Product/" + ProductURL
              }
              target="_blank"
              rel="noreferrer"
            >
              Preview
            </a>
          </div>
        </div>
        <div className="col-8">
          <div className="col-12">
            <input
              className="form-control"
              id="ProductName"
              placeholder="Product Name"
              onChange={(evt) => InputChanged("ProductName", evt)}
              value={ProductName}
            />
          </div>
          <div className="col-12 mt-2">
            <input
              className="form-control"
              id="ProductURL"
              placeholder="Product URL"
              onChange={(evt) => InputChanged("ProductURL", evt)}
              value={ProductURL}
            />
          </div>
          <div className="col-12 mt-2">
            <div className="form-floating">
              <Editor
                apiKey="9rxl097vxew8qnnshg92040bxp2r95vb4cm14f11f509d40y"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={ProductDesciptionInitialValue}
                onEditorChange={(newValue, editor) =>
                  SetProductDesciption(newValue)
                }
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "code"
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help | image" +
                    "| code",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <label htmlFor="ProductDesciption">
                Product Description (HTML)
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 mt-2">
              <button
                className="btn btn-save-product float-end"
                onClick={SaveProductChanges}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-save"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                </svg>{" "}
                Save Changes
              </button>
            </div>
          </div>
          <div className="col-12">
            <h4>Product Images</h4>
          </div>
          <ManageProductImages
            StoreID={StoreID}
            ProductID={ProductID}
            ShowToast={ShowToast}
          />
          <div className="col-12 mt-3">
            <h4>Product Variants</h4>
          </div>
          <ProductVariants
            StoreID={StoreID}
            ProductID={ProductID}
            ShowToast={ShowToast}
          />
        </div>
      </div>
    </Page>
  );
};

export default EditProduct;
