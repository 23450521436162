import React, {  } from "react";
import LogoPng from "../images/logo_new_transparant_white.png";
import "./NavBar.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";

const NavBar = () => {
  const { logout, jwt } = useAuth();
  const navigate = useNavigate();
  const LoggedIn = jwt !== "" ? true : false;

  const Login = () => {
    navigate("/");
  };
  const Logout = () => {
    logout();
    navigate("/");
  };

  const AuthButton = !LoggedIn ? (
    <span className="auth-icon" onClick={Login}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-box-arrow-in-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
        />
        <path
          fillRule="evenodd"
          d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
    </span>
  ) : (
    <span className="auth-icon" onClick={Logout}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-box-arrow-in-left"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
        />
        <path
          fillRule="evenodd"
          d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
        />
      </svg>
    </span>
  );

  return (
    <header className="site-header sticky-top py-0">
      <nav className="navbar container navbar-expand-lg navbar-light py-0">
        <div
          className="order-0"
          style={{
            width: "10%",
          }}
        >
          <a className="navbar-brand mr-auto" href="/">
            <img src={LogoPng} width="120" height="60" alt="Loading..." />
          </a>
        </div>
        <div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="24"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
        </div>
        <div
          className="collapse navbar-collapse order-1 w-100"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li style={{ fontSize: 25 }}>Store Manager</li>
          </ul>
        </div>
        <div
          className="collapse navbar-collapse order-2"
          style={{
            width: "10%",
          }}
        >
          <div className="mx-auto mb-2 mb-lg-0">{AuthButton}</div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
