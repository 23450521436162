import React, { Component } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./Page.css";

class Page extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <div className="site-container">
          <NavBar />
          {this.props.children}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Page;
