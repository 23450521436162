import * as React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Auth from "./authentication/useAuth";
import { RequireAuth } from "./authentication/requireAuth";

//Pages
import HomePage from "./pages/Home";
import StoreOverview from "./pages/StoreOverview";
import ManageStore from "./pages/ManageStore";
import ManageProducts from "./pages/ManageProducts";
import NewProduct from "./pages/NewProduct";
import EditProduct from "./pages/EditProduct";

const App = () => {
  return (
    <Auth>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/storeoverview"
            element={
              <RequireAuth>
                <StoreOverview />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/managestore"
            element={
              <RequireAuth>
                <ManageStore />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/manageproducts"
            element={
              <RequireAuth>
                <ManageProducts />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/newproduct"
            element={
              <RequireAuth>
                <NewProduct />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/editproduct"
            element={
              <RequireAuth>
                <EditProduct />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </Auth>
  );
};

export default App;
